import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import CloseIcon from "../../../assets/icons/new-close.svg";
import SearchIcon from "../../../assets/icons/small-search.svg";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import Auth from "../../../helpers/Auth";
import Success from "../../Toaster/Success/Success";
import AddUserModal from "../AddUserModal";
import UserInformationModal from "../userInformationModal";
import Popper from "popper.js";
import EditIcon from "../../../assets/icons/Edit.svg";
import FilterIcon from "../../../assets/icons/reminder-filter-icon.svg";
import CloseTab from "../../../assets/icons/CloseTab-red.svg";

import "./userModal.scss";
export default function UserModal(props) {
  const { addUserModalOpen, setAddUserModalOpen, userInformationModalOpen, setUserInformationModalOpen, setOpenUserModal, mineRole, toggle } = props;
  const userInfo = Auth.getUserDetail();
  const [allAdminOwner, setAllAdminOwner] = useState([]);
  const [allAdminOwners, setAllAdminOwners] = useState([]);
  const [search, setSearch] = useState("");
  const [userDetails, setUserDetails] = useState();
  const [addUserModal, setAddUserModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastmsg, setToastmsg] = useState();
  const [er, setEr] = useState();

  const [value, setValue] = useState("All account managers");
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const wrapperRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [managerDetails, setManagerDetails] = useState([]);
  const [editUSerData, setEditUserData] = useState({});
  const [showFilter, setShowfilter] = useState(true);
  const [statusFilter, setStatusFilter] = useState([]);
  const [featuresLists, setFeaturesLists] = useState([
    { name: "Appointments", slug: "appointment", isChecked: true, type: "basic" },
    { name: "Invoices", slug: "invoice", isChecked: true, type: "basic" },
    { name: "Inventory", slug: "inventory", isChecked: true, type: "basic" },
    { name: "Customer profile", slug: "customerProfile", isChecked: true, type: "basic" },
    { name: "Customer wallet", slug: "customerWallet", isChecked: true, type: "basic" },
    { name: "Staff profile", slug: "staffProfile", isChecked: true, type: "basic" },
    { name: "Staff attendance", slug: "staff", isChecked: true, type: "basic" },
    { name: "SMS, Notifications", slug: "sms", isChecked: true, type: "basic" },
    { name: "Statement, Daily reports", slug: "statement", isChecked: true, type: "basic" },
    { name: "Product consumption", slug: "product", isChecked: true, type: "basic" },
    { name: "Expenses", slug: "expenses", isChecked: true, type: "basic" },
    { name: "SMS promotion", slug: "smsPromotion", isChecked: true, type: "basic" },
    { name: "Online booking app", slug: "onlineBookingApp", isChecked: true, type: "basic" },
    { name: "Service reminders", slug: "serviceReminder", isChecked: true, type: "basic" },

    { name: "Membership", slug: "membership", isChecked: true, type: "professional" },
    { name: "Staff commission", slug: "staffCommission", isChecked: true, type: "professional" },
    { name: "Staff pay", slug: "staffPayroll", isChecked: true, type: "professional" },
    { name: "Ledger/cash register", slug: "leager", isChecked: true, type: "professional" },
    { name: "Enquiry", slug: "enquiry", isChecked: true, type: "professional" },
    { name: "Roles & Permissions", slug: "rolesPermission", isChecked: true, type: "professional" },
    { name: "Outlets (Branches)", slug: "outlets", isChecked: true, type: "professional" },
    { name: "Outlet admin", slug: "outletsAdmin", isChecked: true, type: "professional" },
  ]);

  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownOpen(true);
  };
  const closeDropdownPopover = () => {
    setDropdownOpen(false);
  };

  const handleSelectInput = (data) => {
    setValue(data?.Name);
    setDropdownOpen(!dropdownOpen);
    if (data?.Name === "All account managers") {
      setAllAdminOwner(allAdminOwners);
    } else {
      let filterData = allAdminOwners?.map((account) => {
        if (account?.accountData[0]?.parentId == data?._id) {
          return account;
        } else {
          if (account?.accountData[0]?.parentId === undefined && data?.role === "SuperAdmin") {
            return account;
          }
        }
      });
      setAllAdminOwner(filterData?.filter((obj) => obj));
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const gettingManagerDetails = async () => {
    await ApiGet("account/manager/getAllmanager")
      .then((res) => {
        // Admin added for select manager Dropdown
        let finalData = [{ count: 0, data: userInfo }].concat(res?.data?.data);
        setManagerDetails(finalData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleAllRole();
    gettingManagerDetails();
  }, []);
  const removeDuplicateObjectFromArray = (array, key) => {
    return new Map(array.map((v) => [v[key], v]));
  };

  const handleAllRole = async () => {
    if (userInfo?.role === "Manager") {
      await ApiGet(`account/manager/count/salon/${userInfo?._id}`)
        .then((res) => {
          console.log(res?.data?.data.reverse());
          setAllAdminOwner(res?.data?.data.reverse());
          setAllAdminOwners(res?.data?.data.reverse());
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      await ApiGet("account/manager/getAllAdminandOwner")
        .then(async (res) => {
          console.log(res?.data?.data.reverse());
          setAllAdminOwner(res?.data?.data.reverse());
          setAllAdminOwners(res?.data?.data.reverse());
     
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const FilterUserData = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setAllAdminOwner(allAdminOwners);
    } else {
      let filterData = allAdminOwners?.filter(
        (obj) =>
          (obj?.accountData[0]?.businessName && obj?.accountData[0]?.businessName.toLowerCase().includes(e.target.value.toLowerCase())) ||
          (obj?.accountData[0]?.mobileNumber && obj?.accountData[0].mobileNumber.toString().includes(e.target.value.toString()))
      );
      setAllAdminOwner(filterData);
    }
  };

  const OpenUserDetailModal = (data) => {
    setUserInformationModalOpen(!userInformationModalOpen);
    setUserDetails(data);
    if (data === true) {
      setSuccess(true);
      setToastmsg("User Deactivated!");
      handleAllRole();
    }
    if (data === false) {
      setSuccess(true);
      setToastmsg("User activated!");
      handleAllRole();
    }
    if (data === "validity") {
      setSuccess(true);
      setToastmsg("validity added!");
      handleAllRole();
    }
    if (data === "upgrade") {
      setSuccess(true);
      setToastmsg("account upgrade!");
      handleAllRole();
    }
  };

  const AddNewuserModal = (data) => {
    setAddUserModal(!addUserModal);
    setEditUserData();
    if (data === true) {
      handleAllRole();
      setSuccess(true);
      setToastmsg("User added sucessfully!");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
      setEr();
    }, 1500);
    return () => clearTimeout(timer);
  });

  const EdituserModal = (data) => {
    setEditUserData(data);
    setAddUserModal(!addUserModal);
    if (data == true) {
      setEditUserData();
      handleAllRole();
      setSuccess(true);
      setToastmsg("User edited sucessfully!");
    }
  };

  const userStatusFilter = (e, key) => {
    let filter;
    if (statusFilter?.includes(key)) {
      let index = statusFilter.indexOf(key);
      statusFilter.splice(index, 1);
      setStatusFilter([...statusFilter]);
    } else {
      statusFilter?.push(key);
      setStatusFilter([...statusFilter]);
    }

    if (statusFilter) {
      filter = allAdminOwners?.filter(
        (fil) =>
          (statusFilter?.includes("Deactive") && fil?.accountData[0]?.isActive === false) ||
          (statusFilter?.includes("Active") && fil?.accountData[0]?.isActive === true) ||
          (statusFilter?.includes("Expired") && fil?.accountData[0]?.isActive === true && fil?.noOfDays === 0) ||
          (statusFilter?.includes("Paid") && fil?.monetize[0]?.paymentData?.length != 0 && fil?.monetize?.length != 0) ||
          (statusFilter?.includes("Unpaid") && (fil?.monetize[0]?.paymentData?.length == 0 || fil?.monetize?.length == 0))
      );
      setAllAdminOwner(filter);
    }

    if (statusFilter?.length == 0) {
      setAllAdminOwner(allAdminOwners);
    }
  };

  return (
    <>
      <div className="user-modal-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => setAddUserModalOpen(false)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Users</h2>
          </div>
          <div className="blue-button" onClick={() => AddNewuserModal()}>
            <button>Add new user</button>
          </div>
        </div>
        <div className="full-page-body-top-alignment">
          <div className="all-content-alignment">
            <div className="user-modal-all-data-width">
              <div className="sub-header-alignment">
                <div className="manager-filter-align">
                  <h5>Total number of user accounts created - {allAdminOwner?.length} </h5>
                  {userInfo?.role !== "Manager" && (
                    <div
                      className="relative-custom-select"
                      ref={wrapperRef}
                      onClick={() => {
                        dropdownOpen ? closeDropdownPopover() : openDropdownPopover();
                      }}
                    >
                      <div className="search-relative select-relative-div" ref={btnDropdownRef}>
                        <input type="text" style={{ cursor: "pointer" }} disabled value={value} />
                        <div className="search-icon-alignment">
                          <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 0.5L4 3.5L7 0.5" stroke="#1479FF" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>

                      <div
                        className={
                          dropdownOpen
                            ? "custom-select-react-dropdown custom-select-react-dropdown-open"
                            : "custom-select-react-dropdown custom-select-react-dropdown-hidden"
                        }
                        ref={popoverDropdownRef}
                      >
                        <div className="all-menu-alignment" onClick={(e) => handleSelectInput({ Name: "All account managers" })}>
                          <div className="menu-list">
                            <span>{"All account managers"}</span>
                          </div>
                        </div>
                        {managerDetails?.map((drop) => {
                          return (
                            <div className="all-menu-alignment" onClick={(e) => handleSelectInput(drop?.data)}>
                              <div className="menu-list">
                                <span>{drop?.data?.Name}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <div className="search-relative">
                    <input type="text" placeholder="Search user" value={search} onChange={(e) => FilterUserData(e)} />
                    <div className="search-icon-alignment">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </div>
                  </div>
                </div>
              </div>
              {userInfo?.role !== "Manager" && (
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                  <div
                    className={
                      showFilter ? "sub-button-style-filters cus-invoice-icon-designs-are" : "cus-invoice-icon-designs cus-invoice-icon-designs-are"
                    }
                    onClick={() => (showFilter ? "" : setShowfilter(!showFilter))}
                  >
                    {!showFilter && (
                      <div className="iconic-tab">
                        <div className="iconic-icon">
                          <img src={FilterIcon} alt="FilterIcon" />
                        </div>
                        <div className="iconic-icon-hover">
                          <img src={FilterIcon} alt="FilterIcon" />
                        </div>
                        <p className="iconic-title">Filters</p>
                      </div>
                    )}
                    {showFilter ? (
                      <div style={{ display: "flex", marginRight: "15px" }}>
                        <div
                          className={statusFilter.includes("Active") ? "filter-data-btns-paid-active " : "filter-data-btns-paid"}
                          onClick={(e) => userStatusFilter(e, "Active")}
                        >
                          <button>Active</button>
                        </div>
                        <div
                          className={statusFilter.includes("Deactive") ? "filter-btn-clicked-active " : "filter-btn-clicked"}
                          onClick={(e) => userStatusFilter(e, "Deactive")}
                        >
                          <button>Deactive</button>
                        </div>
                        <div
                          className={statusFilter.includes("Expired") ? "filter-btn-clicked-expire-active " : "filter-btn-clicked-expire"}
                          onClick={(e) => userStatusFilter(e, "Expired")}
                        >
                          <button>Expired</button>
                        </div>
                        <div
                          className={statusFilter.includes("Paid") ? "filter-btn-clicked-paid-active " : "filter-btn-clicked-paid"}
                          onClick={(e) => userStatusFilter(e, "Paid")}
                        >
                          <button>Paid</button>
                        </div>
                        <div
                          className={statusFilter.includes("Unpaid") ? "filter-btn-clicked-active " : "filter-btn-clicked"}
                          onClick={(e) => userStatusFilter(e, "Unpaid")}
                        >
                          <button>Unpaid</button>
                        </div>

                        {/* <div style={{ marginLeft: "15px", marginTop: "2px" }}>
                        <img src={CloseTab} alt="CloseTab-red" style={{ marginRight: "14px" }} onClick={() => setShowfilter(!showFilter)} />
                      </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              <div className="user-data-table-show">
                <table>
                  <tr>
                    <th align="left">#</th>
                    <th align="left">Date</th>
                    <th align="left">Salon name</th>
                    <th align="left">Type</th>
                    <th align="left">Mobile number</th>
                    <th align="left">Country</th>
                    <th align="left">Validity</th>
                    <th align="center">Account status</th>
                    <th align="center">Payment status</th>
                    {userInfo?.role !== "Manager" && <th align="center"></th>}
                  </tr>
                  {allAdminOwner.map((elem, i) => {
                    return (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          OpenUserDetailModal(elem);
                        }}
                      >
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{i + 1}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{moment(elem?.accountData[0]?.created).format("DD MMM YY")}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.accountData[0]?.businessName}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.accountData[0]?.role}</span>
                          </div>
                        </td>
                        <td align="left">
                        <div className="normal-text-style" style={{minWidth: "160px"}}>
                            <span>+{elem?.accountData[0]?.dialCode} {elem?.accountData[0]?.mobileNumber}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.accountData[0]?.countryName}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.noOfDays} days</span>
                          </div>
                        </td>
                        {elem?.accountData[0]?.isActive ? (
                          elem?.noOfDays === 0 ? (
                            <td align="center">
                              <div className="status-button-style-expire">
                                <button>{"Expired"}</button>
                              </div>
                            </td>
                          ) : (
                            <td align="center">
                              <div className="status-button-style">
                                <button>{elem?.accountData[0]?.isActive && "Active"}</button>
                              </div>
                            </td>
                          )
                        ) : (
                          <td align="center">
                            <div className="status-button-style-deactive">
                              <button>{"Deactivated"}</button>
                            </div>
                          </td>
                        )}
                        {elem?.noOfDays !== 0 && elem?.monetize[0]?.paymentData?.length != 0 ? (
                          <td align="center">
                            <div className="paid-button-style">
                              <button>Paid</button>
                            </div>
                          </td>
                        ) : elem?.noOfDays == 0 && elem?.monetize?.length == 0 ? (
                          <td align="center"></td>
                        ) : elem?.noOfDays == 0 && elem?.monetize[0]?.paymentData?.length == 0 ? (
                          <td align="center"></td>
                        ) : elem?.noOfDays == 0 && elem?.monetize[0]?.paymentData?.length != 0 ? (
                          <td align="center">
                            <div className="paid-button-style">
                              <button>Paid</button>
                            </div>
                          </td>
                        ) : (
                          <td align="center">
                            <div className="paid-button-style">
                              <button>Unpaid</button>
                            </div>
                          </td>
                        )}

                        {userInfo?.role !== "Manager" && (
                          <td align="left">
                            <div
                              onClick={(e) => {
                                EdituserModal(elem?.accountData[0]);
                                e.stopPropagation();
                              }}
                            >
                              <img src={EditIcon} alt="EditIcon" />
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                  {userInformationModalOpen && (
                    <UserInformationModal toggle={OpenUserDetailModal} userDetails={userDetails} EdituserModal={EdituserModal} />
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addUserModal && (
        <AddUserModal toggle={AddNewuserModal} editUSerData={editUSerData} EdituserModal={EdituserModal} addUserModal={addUserModal} />
      )}
      {success && <Success modal={success} toastmsg={toastmsg} />}
    </>
  );
}
